import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { SiChatbot } from "react-icons/si";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { MdNotificationsNone } from "react-icons/md";
import Chat from "../pages/chat/Chat";
import BulkMessages from "./BulkMessages";
import Temp from "./App";
import App from "../Chatbot/App";
import DataTable from "./TemplateData";
import Contacts from "../Contact Detail/Contacts";
import Admin_profile from "../Admin/Admin_profile";
import logo1 from "../pages/components/images/logo1-removebg-preview.png";
import logo2 from "../pages/components/images/aws_logo.png";
import Template from "../pages/components/images/Template.png";
import Newtemp from "../pages/components/images/Create new.png";
import Viewtemp from "../pages/components/images/View Template.png";
import Createborad from "../pages/components/images/Create broadcast.png";
import Contact from "../pages/components/images/Contact.png";
import Chatbot from "../pages/components/images/chatbotbuilder.png";
import Chating from "../pages/components/images/CHAT.png";
import Setting from "../pages/components/images/Settings.png";
import VUser from "../pages/components/images/View user.png";
import Lgout from "../pages/components/images/Logout.png";
import ViewAdmin from "../Admin/ViewAdmin";
import Temp_Build from "./Temp_Bulid";
import ExcelG from '../pages/components/images/simple excel sheet (2).png';
import ContactIc from '../pages/components/images/Add user.png';
import { useSelector, useDispatch } from "react-redux";
import { Rightscreen } from "../Redux Toolkit/slice";
import { useAuth } from "../pages/Auth/AuthContext";
import "../Chatbot/index.css";
import "reactflow/dist/style.css";
import '../Style/CSS.css';
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";
import axios from "axios";
import ContactFormModal6 from "../pages/ContactForm/ContactForm6";
import ContactFormModal2 from "../pages/ContactForm/ContactForm2";
import ContactFormModal from "../pages/ContactForm/ContactForm";

const Dashboard = () => {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const decod = decodeToken(jwtToken);
  const { decodedToken } = decod;
  const { userId } = decodedToken;
  const { logout, role } = useAuth();
  const [white, setWhite] = useState(true);
  const [Toggleleftscreen, setToggleleftscreen] = useState(true);
  const [messageTemplateArrow, setMessageTemplateArrow] = useState(false);
  const [messageTemplateFunctionality, setMessageTemplateFunctionality] =
    useState(false);
  const [adminData, setAdminData] = useState(false);
  const [roleChk, setRoleCHK] = useState(true);
  const [screenName, setScreenName] = useState("Dashboard");
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showContactModal2, setshowContactModal2] = useState(false);
  const [sendMessageArrow, setSendMessageArrow] = useState(false);
  const [sendMessageFunctionality, setSendMessageFunctionality] =
    useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
   

  const toggleMessageTemplate = () => {
    setMessageTemplateArrow(!messageTemplateArrow);
    setMessageTemplateFunctionality(!messageTemplateFunctionality);
  };
  const [broadcast, setbroadcast] = useState(false);
  const [broadcastFunctionality, setbroadcastFunctionality] = useState(false);
  const rightSCreen = useSelector((state) => state.Data.count);
  const dispatch = useDispatch();
  const changeScreen = (value) => {
    dispatch(Rightscreen(value));
  };

  useEffect(() => {
    console.log(role);
    if (role === "admin") {
      setRoleCHK(false);
      console.log(roleChk);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);
  const togglebroadcast = () => {
    setbroadcast(!broadcast);
    setbroadcastFunctionality(!broadcastFunctionality);
  };
  //used to change right side page data
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };
  const closeContactModal2 = () => {
    setshowContactModal2(false);
  };
  const openContactModal = () => {
    setshowContactModal(true);
  };
  const openContactModal2 = () => {
    setshowContactModal2(true);
  };
  var right = "";
  if (rightSCreen === "") {
    right = (
      <div>
        <div className="gap-3 m-0 text-center center d-flex flex-column align-items-center ">
          <SiChatbot className="fs-2 " style={{ fontWeight: "light" }} />
          <div>
            <h4>Welcome to AWS CRM</h4>
            <p>
              Your one stop solution to manage your WhatsApp Business Account
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (rightSCreen === "1") {
    right = <Temp />;
  }
  if (rightSCreen === "2") {
    right = <BulkMessages />;
  }
  if (rightSCreen === "4") {
    right = <DataTable />;
  }
  if (rightSCreen === "5") {
    right = <Contacts />;
  }
  if (rightSCreen === "6") {
    right = <Chat />;
  }
  if (rightSCreen === "7") {
    right = <Admin_profile />;
  }
  if (rightSCreen === "8") {
    right = <ViewAdmin />;
  }
  if (rightSCreen === "9") {
    right = <Temp_Build />;
  }
  if(rightSCreen === "10"){
    right = <App />
  }

  // Function to toggle the background color
  const toggleBackgroundColor = () => {
    setWhite(!white);
  };
  useEffect(() => {
    console.log(role);
    if (role === "admin") {
      setRoleCHK(false);
      console.log(roleChk);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);

  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins/${userId}`);
      console.log(response.data);
      setAdminData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  // Function to toggle the visibility and functionality display for Message Template

  // Function to toggle the visibility and functionality display for Send Message
  const toggleSendMessage = () => {
    setSendMessageArrow(!sendMessageArrow);
    setSendMessageFunctionality(!sendMessageFunctionality);
  };
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);

  const toggleTemplate = () => {
    setIsTemplateOpen(!isTemplateOpen);
  };
  const toggleSetting = () => {
    setIsSettingOpen(!isSettingOpen);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="collapse-toggle" onClick={toggleCollapse}>
    {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
  </div>

        <div style={{ marginTop: 2, marginLeft: -2 }}  onClick={() => { setScreenName('Dashboard'); changeScreen("") }}>
          {white ? (
            <img src={logo1} alt="error" height="40px" width="99px" />
          ) : (
            <img
              src={logo2}
              alt="error"
              height="32px"
              width="99px"
              style={{ marginTop: 4 }}
            />
          )}
        </div>
        <div className="menu">
          <div className="menu-item" onClick={toggleTemplate}>
            <div className="menu-icon-text">
              <img src={Template} height={35} width={35} />
              {!isCollapsed && <span style={{ fontSize: 24, color: '#565656' }}>Templates</span>}
            </div>
            {!isCollapsed && (isTemplateOpen ? <FaChevronDown /> : <FaChevronRight />)}
          </div>
          <div className={`submenu ${isTemplateOpen ? '' : 'collapsed'}`}>
            <div className="submenu-item" onClick={() => { setScreenName('Create New Template'); changeScreen("1") }}>
              <img src={Newtemp} height={25} width={25} />
              {!isCollapsed && <span style={{ fontSize: 20, color: '#565656' }}>Create Template</span>}
            </div>
            <div className="submenu-item" onClick={() => { setScreenName('View Templates'); changeScreen("4") }}>
              <img src={Viewtemp} height={25} width={25} />
              {!isCollapsed && <span style={{ fontSize: 20, color: '#565656' }}>View Templates</span>}
            </div>
            <div className="submenu-item" onClick={() => { setScreenName('Message Template'); changeScreen("2") }}>
              <img src={Createborad} height={25} width={25} />
              {!isCollapsed && <span style={{ fontSize: 20, color: '#565656' }}>Create Broadcast</span>}
            </div>
          </div>
          <div className="menu-item" onClick={() => { setScreenName('Contact'); changeScreen("5") }}>
            <div className="menu-icon-text">
              <img src={Contact} height={35} width={35} />
              {!isCollapsed && <span style={{ fontSize: 24, color: '#565656' }}>Contact</span>}
            </div>
          </div>
          <div className="menu-item" onClick={() => { setScreenName('Chatbot Builder'); changeScreen("10") }}>
            <div className="menu-icon-text">
              <img src={Chatbot} height={35} width={35} />
              {!isCollapsed && <span style={{ fontSize: 24, color: '#565656' }}>Chatbot Builder</span>}
            </div>
          </div>
          <div className="menu-item" onClick={() => { setScreenName('Messages'); changeScreen("6") }}>
            <div className="menu-icon-text">
              <img src={Chating} height={35} width={35} />
              {!isCollapsed && <span style={{ fontSize: 24, color: '#565656' }}>Chat</span>}
            </div>
          </div>
          <div className="menu-item" onClick={toggleSetting}>
            <div className="menu-icon-text">
              <img src={Setting} height={35} width={35} />
              {!isCollapsed && <span style={{ fontSize: 24, color: '#565656' }}>Settings</span>}
            </div>
            {!isCollapsed && (isSettingOpen ? <FaChevronDown /> : <FaChevronRight />)}
          </div>
          <div className={`submenu ${isSettingOpen ? '' : 'collapsed'}`}>
            {roleChk &&
              <div className="submenu-item" onClick={() => { setScreenName('View User'); changeScreen("8") }}>
                <img src={VUser} height={25} width={25} />
                {!isCollapsed && <span style={{ fontSize: 20, color: '#565656' }}>View User</span>}
              </div>
            }
            <div className="submenu-item" onClick={logout}>
              <img src={Lgout} height={25} width={25} />
              {!isCollapsed && <span style={{ fontSize: 20, color: '#565656' }}>Logout</span>}
            </div>
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="header">
          <div className="left-section">
            <span className="screen-name">{screenName}</span>
            {screenName === "Contact" ? (
              <div className="icon-group">
                <img src={ExcelG} height={35} width={35} onClick={handleShowModal} />
                <img src={ContactIc} height={35} width={35} onClick={openContactModal} />
              </div>
            ) : screenName === "View User" ? (
              <div className="icon-group">
                <img src={ContactIc} height={35} width={35} onClick={openContactModal2} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="profile-section">
            <MdNotificationsNone size={24} className="notification-icon" />
            <div className="profile-info">
              <img
                src={
                  adminData.profilePicture
                    ? `data:image/jpeg;base64,${adminData.profilePicture}`
                    : "https://bootdey.com/img/Content/avatar/avatar1.png"
                }
                style={{
                  cursor: 'pointer',
                  borderColor: 'goldenrod',
                  borderWidth: 1,
                  borderRadius: '50%',
                }}
                height={40}
                width={40}
                onClick={() => {
                  setScreenName('Profile');
                  changeScreen("7");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`${white ? "Theme2" : "Theme1"} cursor-pointer m-0 p-0`}
          style={{
            width: Toggleleftscreen ? "100%" : "100%",
            maxHeight: '91vh',
            overflowY: 'none',
            backgroundColor: "white",
            color: "black",
          }}
        >
          <p>{right}</p>
        </div>
      </div>
      <ContactFormModal6 isOpen={showModal} onClose={handleCloseModal} />
      <ContactFormModal2 isOpen={showContactModal2} onClose={closeContactModal2} />
      <ContactFormModal isOpen={showContactModal} onClose={closeContactModal} />
    </div>
  );
};

export default Dashboard;
