import React from "react";
import { Handle, Position } from "reactflow";

// Custom node
function Trigger({ data, selected }) {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "\n"+text.substring(maxLength, );
    }
    return text;
  };
  return (
    <div
    style={{ borderColor: selected ? "#ffca28" : "#e0e0e0", boxShadow: selected ? "0 4px 8px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.1)" }}
    className={`template w-48 shadow-lg rounded-lg bg-white ${selected ? "border-solid border-1" : ""}`}
  >
      <div className="flex flex-col">
      <div
  style={{
    background: "linear-gradient(to right, #5EEAD4, #9333EA)"
  }}
  className="max-h-max px-2 py-1 text-left text-white text-xs font-bold rounded-t-md"
>
  ⚡ Trigger
</div>



        <div className="px-3 py-2">
          <div style={{fontSize:10}} className="text-xs font-normal text-gray-500">
          {truncateText(data.label, 20)}
          </div>
        </div>
      </div>

      <Handle
        id="b"
        type="source"
        position={Position.Right}
        className="w-1 rounded-full bg-gray-500"
      />
    </div>
  );
}

export default Trigger;
