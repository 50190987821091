import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";

function TextNode({ data, id, selected }) {
  const [input, setInput] = useState(data.label || "");
  const [charCount, setCharCount] = useState(0);
  const { setNodes } = useReactFlow();
  const textareaRef = useRef(null);

  useEffect(() => {
    setInput(data.label || "");
    setCharCount(data.label ? data.label.length : 0);
  }, [data]);

  useEffect(() => {
    autoResizeTextarea();
  }, [input]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleChange = (event) => {
    let { value } = event.target;
    autoResizeTextarea();

    if (value.length > 550) {
      value = value.slice(0, 550);
    }

    const words = value.split(" ");
    let formattedValue = "";
    let currentLine = "";

    words.forEach((word) => {
      if (currentLine.length + word.length + 1 > 20) {
        formattedValue += currentLine + "\n";
        currentLine = word;
      } else {
        if (currentLine.length > 0) {
          currentLine += " ";
        }
        currentLine += word;
      }
    });

    if (currentLine.length > 0) {
      formattedValue += currentLine;
    }

    setInput(value);
    setCharCount(value.length);

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, label: value };
        }
        return node;
      })
    );
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
    }
  };

  return (
    <div
      style={{ borderColor: selected ? "#ffca28" : "#e0e0e0", boxShadow: selected ? "0 4px 8px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.1)" }}
      className={`template w-48 shadow-lg rounded-lg bg-white ${selected ? "border-solid border-1" : ""}`}
    >
      <div className="flex flex-col">
        <div
          style={{
            background: "linear-gradient(to right, #4BC0C8, #2C3E50)"
          }}
          className="max-h-max px-2 py-1 rounded-t-md text-xs text-left text-white font-bold"
        >
          ✉️ Message <span className="ml-5 font-normal text-white">{charCount}/550</span>
        </div>

        <div className="px-2 pt-2">
          <div
            style={{ width: 180}}
            className="text-xs font-normal text-black"
          >
            <textarea
              ref={textareaRef}
              name="inp"
              value={input}
              onChange={handleChange}
              style={{
                minHeight: "30px",
                maxHeight: "100px",
                width: "100%",
                border: "none",
                outline: "none",
                resize: "none",
                overflowY: "auto",
                fontSize: "12px",
                fontWeight: "500",
                color: "#4b5563",
                scrollbarWidth: "thin", // For Firefox
                WebkitScrollbar: {
                  width: "4px",
                },
                WebkitScrollbarTrack: {
                  background: "#f1f1f1",
                },
                WebkitScrollbarThumb: {
                  background: "#888",
                  borderRadius: "4px",
                },
                WebkitScrollbarThumbHover: {
                  background: "#555",
                },
              }}
              className="custom-scrollbar" // For WebKit
              placeholder="Enter Message"
            />
          </div>
        </div>
        <div>
          <div
            style={{ borderTopWidth: 1, borderColor: "#d3d3d3" }}
            className="max-h-max mt-1 px-1 text-center fs-8 text-gray-600 text-xs"
          >
            wrong response
            <Handle
              style={{
                position: "absolute",
                right: -3,
                top: "91%",
                transform: "translateY(-50%)",
              }}
              id="bs"
              type="source"
              position={Position.Right}
              className="w-1 rounded-full bg-gray-500"
            />
          </div>
        </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
      <Handle
        id="b"
        type="source"
        position={Position.Right}
        className="w-1 rounded-full bg-gray-500"
      />
    </div>
  );
}

export default TextNode;
