import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  Panel,
  Background,
} from "reactflow";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete} from "react-icons/md";
import { LuRefreshCcw } from "react-icons/lu";
import "reactflow/dist/base.css";
import Sidebar from "./component/sidebar";
import Sidebarlist from "./component/sidebarlist";
import TextNode from "./component/TextNode";
import UrlButtons from "./component/UrlButtons";
import MessageNode from "./component/MessageNode";
import Trigger from "./component/Trigger";
import Popup from "./component/Popup";
import "./index.css";
import BotName from "./component/BotName";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import del  from "../pages/components/images/delete.png";
import up  from "../pages/components/images/upload.png";
import restore  from "../pages/components/images/restore.png";
import { useSelector, useDispatch } from "react-redux";
import { Rightscreen ,IsTemplate} from "../Redux Toolkit/slice";
import { NavItem } from "react-bootstrap";

const initialNodes = [
  {
    id: "1",
    type: "triggernode",
    data: { label: "" },
    position: { x: 250, y: 5 },
  },
];

const nodeTypes = {
  textnode: TextNode,
  buttons: UrlButtons,
  triggernode: Trigger,
  message: MessageNode,
};

const App = () => {
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [botNameVisible, setbotNameVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [botname, setBotName] = useState("");
  const [botType, setBotType] = useState("");
  const [selectedNodes, setSelectedNodes] = useState("");
  const [pendingRestoreData, setPendingRestoreData] = useState(null); // New state to track pending restore data

  const getId = useCallback(() => `${botname}_${Math.floor(Math.random() * 1000)}`, [botname]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onSelectionChange = useCallback((elements) => {
    if (elements && elements.nodes && elements.nodes.length > 0) {
      setSelectedNodes(elements.nodes[0].id);
    }
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type}` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes, getId]
  );
  const getButtonsData = (node, flowData) => {
    const buttonsData = [];
        if (node.data.showButtons) {
          const buttonEdges = flowData.edges.filter((e) => e.source === node.id);
          node.data.inputs.forEach((input, index) => {
            const targetEdge = buttonEdges[index];
            buttonsData.push({
              type: input.link ? "URL" : "QUICK_REPLY",
              text: input.text,
              url: input.link || "",
              nextid: input.link ? "" : targetEdge ? targetEdge.target : "",
            });
          });
        }
      
  
    return buttonsData;
  };
  const onSave = useCallback(async () => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      const flowData = {
        nodes: flow.nodes,
        edges: flow.edges,
      };
      flowData.nodes.map((node) => node.id!==1?node.id=node.id+11:"")
      flowData.edges.map((node) =>  node.source===1?node.target=node.target+11:(node.target=node.target+11,node.source=node.source+11))
      const templates = await axios.get(`${ApiEndPoint}/getTemplate`);
      const localtemplates = await axios.get(`${ApiEndPoint}/getBotDataById/${botname}`);
      const structuredData = [];
      const nodeMap = new Map(flowData.nodes.map((node) => [node.id, node]));

      flowData.nodes.forEach((node) => {
        if (node.type === "textnode" || node.type === "message") {
          const connectedEdges = flowData.edges.filter(
            (edge) => edge.source === node.id
          );
          let buttonsData = [];

          connectedEdges.forEach((edge) => {
            const targetNode = nodeMap.get(edge.target);
            if (targetNode && targetNode.type === "buttons") {
              const buttonEdges = flowData.edges.filter(
                (e) => e.source === targetNode.id
              );
              targetNode.data.inputs.forEach((input, index) => {
                const targetEdge = buttonEdges[index];
                buttonsData.push({
                  type: input.link ? "URL" : "QUICK_REPLY",
                  text: input.text,
                  url: input.link || "",
                  nextid: input.link ? "" : targetEdge ? targetEdge.target : "",
                });
              });
            }
          });
          if(node.type === "message"){
            if (node.data.showButtons) {
             buttonsData = getButtonsData(node, flowData);
            }
          }       

          const wrongEdges = flowData.edges.filter(
            (e) => e.source === node.id && e.sourceHandle === "bs"
          );

          let wrongId = "";
          if (wrongEdges.length > 0) {
            const firstWrongEdge = wrongEdges.find((e) => e && e.target);
            if (firstWrongEdge) {
              wrongId = firstWrongEdge.target;
            }
          }

          if (localtemplates && localtemplates.data && localtemplates.data.data) {
            localtemplates.data.data.Templates.forEach((items) => {
              templates.data.data.map(async (item) => {
                if (item.name === items.template_name && item.language === "en_US") {
                  console.log('enter in condition');
                  try {
                    const response = await axios.delete(`${ApiEndPoint}/message-templates/`, {
                      data: {
                        id: item.id,
                        name: item.name,
                      },
                    });
                    console.log('item deletes');
                  } catch (error) {
                    console.error('Error deleting item:', error);
                  }
                }
              });
            });
          } else {
            console.error('localtemplates.data.data.Templates is undefined');
          }
          

          structuredData.push({
            template_name: node.id,
            language: "en_US",
            category: "MARKETING",
            body: node.data.label,
            buttonsData: buttonsData,
            wrongId: wrongId,
            status: false, // Add status here
          });
        }
      });
      const tmpData = [
        {
          name: botname,
          Templates: structuredData,
          status: botType === "broadcast" ? true : false,
        },
      ];

      console.log("tempdata",tmpData)
      if (botType === "broadcast") {
        try {
          const response = await fetch(`${ApiEndPoint}/getBotData`);
          const data = await response.json();
          if (data) {
            if (Array.isArray(data["localJsonData"])) {
              data["localJsonData"].map(async (e) => {
                if (e.status) {
                  try {
                    const response = await fetch(
                      `${ApiEndPoint}/updateBotStatus`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ name: e.name, status: false }),
                      }
                    );
                    if (!response.ok) {
                      throw new Error("Network response was not ok");
                    }
                  } catch (error) {
                    console.error("Error updating bot status:", error);
                  }
                }
              });
            } else {
              console.error("Data is not an array:", data);
            }
          } else {
            console.error("Fetched data is not an array:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      fetch(`${ApiEndPoint}/create-message-template`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tmpData: tmpData, flow: flow }),
      })
        .then((response) => console.log(response))
        .then((data) => {
          if (botType === "broadcast") {
          dispatch(IsTemplate(structuredData[0].template_name));
         dispatch(Rightscreen("2"));
          }

        })
        .catch((error) => console.error("Error:", error));
      localStorage.setItem(`${botname}`, JSON.stringify(flow));
      setPopupMessage("Flow saved successfully!");
      setPopupVisible(true);
    }
  }, [reactFlowInstance, botname]);

  const autoSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localStorage.setItem(`${botname}`, JSON.stringify(flow));
    }
  }, [reactFlowInstance, botname]);

  useEffect(() => {
    const intervalId = setInterval(autoSave, 60000);
    return () => clearInterval(intervalId);
  }, [autoSave, botname, botType]);

  useEffect(() => {}, [botname, botType]);

  const deleteNodeById = () => {
    if (reactFlowInstance) {
      if (selectedNodes !== "1") {
        reactFlowInstance.setNodes((nds) =>
          nds.filter((node) => node.id !== "" + selectedNodes + "")
        );
      }
      setSelectedNodes("");
    }
  };

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(`${botname}`));
      if (flow) {
        const { nodes, edges, viewport } = flow;
        setNodes(nodes || []);
        setEdges(edges || []);
        reactFlowInstance.setViewport(viewport);
      }
    };

    if (reactFlowInstance) {
      restoreFlow();
    }
  }, [reactFlowInstance, setNodes, setEdges, botname]);

  const handleBotNameLoad = async (name,data) => {
    if (data && name) {
      setBotName(name);
      setPendingRestoreData(data);
    }

    setVisible(true);
  };

  const onRestoreByClick = useCallback(
    (data) => {
      return new Promise((resolve, reject) => {
        const restoreFlow = async () => {
          if (data["data"]["flowData"]) {
            const { nodes, edges, viewport } = data["data"]["flowData"];
            setNodes(nodes || []);
            setEdges(edges || []);
            reactFlowInstance.setViewport(viewport);
          }
          resolve(); // Resolve the promise when the flow is restored
        };

        if (reactFlowInstance) {
          restoreFlow().catch(reject); // Call restoreFlow and handle errors
        } else {
          resolve(); // Resolve immediately if reactFlowInstance is not available
        }
      });
    },
    [reactFlowInstance, setNodes, setEdges]
  );

  useEffect(() => {
    if (reactFlowInstance && pendingRestoreData) {
      onRestoreByClick(pendingRestoreData).then(() => {
        setPendingRestoreData(null); // Clear pending restore data
      });
    }
  }, [reactFlowInstance, pendingRestoreData, onRestoreByClick]);

  const closePopup = () => {
    setPopupVisible(false);
    setbotNameVisible(false);
    setVisible(false);
    // window.location.reload();
  };

  const closeBotNamePopup = () => {
    setbotNameVisible(false);
    setVisible(false);
  };

  const handleBotNameSave = (details) => {
    setBotName(details.botName);
    setBotType(details.botType);
    console.log(details.botType);
    setbotNameVisible(false);
    setNodes((nds) => {
      const updatedNodes = nds.map((node) =>
        node.id === "1"
          ? { ...node, data: { ...node.data, label: details.botName } }
          : node
      );
      return updatedNodes;
    });
  };

  const handleNewBot = () => {
    setNodes(initialNodes);
    setEdges([]);
    setVisible(true);
    setbotNameVisible(true);
  };

  return (
    <div style={{height:'91vh'}} className="dndflow">
      <ReactFlowProvider>
        <Sidebarlist onSave={handleBotNameLoad} onNew={handleNewBot} />
        {visible && <Sidebar />}
        {visible && (
          <div
            style={{ backgroundColor: "white" }}
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onSelectionChange={onSelectionChange}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              fitView
            >
              <Background variant="dots" gap={12} size={1} />
              <Controls
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  alignItems: "center",
                  marginLeft: 5,
                  marginTop: 440,
                  width: 10,
                }}
              />
              <MiniMap style={{ display: "none" }} />
              <Panel
                style={{
                  marginLeft: -260,
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
                position="top-left"
              >
                <button
                  
                  className=" text-white font-bold p-2 rounded"
                  onClick={onSave}
                  title="Upload Flow"
                >
                  <img src={up} height={45} width={45} />
                </button>
                <button
                  className="text-white font-bold p-2 rounded"
                  onClick={onRestore}
                  title="Restore Flow"
                >
                  <img src={restore} height={45} width={45} />
                </button>
                <button                  
                  className="text-white font-bold p-2 rounded"
                  onClick={deleteNodeById}
                  title="Delete Node"
                >
                  <img src={del} height={45} width={45} />
                </button>
              </Panel>
            </ReactFlow>
          </div>
        )}
      </ReactFlowProvider>
      {botNameVisible && (
        <BotName onSave={handleBotNameSave} onClose={closeBotNamePopup} />
      )}
      {popupVisible && <Popup message={popupMessage} onClose={closePopup} />}
    </div>
  );
};

export default App;
