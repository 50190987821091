import React from "react";
import '../index.css';
import 'reactflow/dist/style.css';
import messageicon from '../../pages/components/images/Message.png'
import button from '../../pages/components/images/Button.png'
import temp from '../../pages/components/images/Template.png'

export default function Sidebar({
  nodeName,
  setNodeName,
  selectedNode,
  setSelectedElements,
}) {
 
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside style={{width:'12%',borderRadius:2,padding:4,backgroundColor:'white',height:'91vh'}} className="border-r-2 pt-3  border-red text-sm w-64 h-screen text-black">
        <>
          <span style={{fontSize:22,color:'#565656'}} className="text-xl fw-sm mb-14">Components</span>
          <div
          style={{fontSize:16,color:'#565656'}}
            className="p-2 rounded mt-6 cursor-move flex justify-left items-center hover:bg-blue-500 hover:text-white transition-colors duration-200"
            onDragStart={(event) => onDragStart(event, "textnode")}
            draggable
          >
           <img src={messageicon} height={25} width={25}/> <span>Message</span>
          </div>
          <div
            style={{marginTop:15,fontSize:16,color:'#565656'}}
            className="p-2 mt-15 rounded cursor-move flex justify-left items-center  hover:bg-blue-500 hover:text-white transition-colors duration-200"
            onDragStart={(event) => onDragStart(event, "buttons")}
            draggable
          >
            <img src={button} height={25} width={25}/> Buttons
          </div>
          <div
            style={{marginTop:15,fontSize:16,color:'#565656'}}
            className="p-2 mt-15 rounded cursor-move flex justify-left items-center  hover:bg-blue-500 hover:text-white transition-colors duration-200"
            onDragStart={(event) => onDragStart(event, "message")}
            draggable
          >
             <img src={temp} height={25} width={25}/> Template
          </div>
        </>
    </aside>
  );
}
